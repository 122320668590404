




























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import Util from '@/lib/util';
import VWidget from '@/components/VWidget.vue';
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'

@Component({
    components: { apexcharts: VueApexCharts, VWidget },
})
export default class Results extends ListBase {
    loadingDash: boolean = false;
    options: any = {
        chart: {
            type: 'bar',
            height: 350,
        },
        //colors: colors,
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '40%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";                    
            },
            style: {
                fontSize: '12px',
            }
        },
        xaxis: {
            categories: this.categories,
            labels: {
                style: {
                    //colors: colors,
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        legend: {
            show: false
        },
    };
    options2: any = {
        chart: {
            type: 'bar',
            height: 350,
        },
        //colors: colors,
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '40%',
                distributed: true,
            }
        },
        xaxis: {
            categories: this.categories,
            labels: {
                style: {
                    //colors: colors,
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        legend: {
            show: false
        },
    };

    get sorted_candidates() {
        return this.election.applicants.sort((a, b) => { return b.votes - a.votes; });
    }

    get categories() {
        let list = [];
        this.sorted_candidates.forEach(item => {
            if (this.typeCode == 'LAW')
                list.push(item.name.split(' '));
            else
                list.push(item.name);
        });

        return list;
    }

    get series() {
        let votes = [];
        //let pesos = [];
        this.sorted_candidates.forEach(item => {
            //votes.push(item.votes);
            votes.push(item.votes * 100 / this.election.votes);
        });

        return [{
            name: 'Peso',
            data: votes
        }];
    }

    get series2() {
        let votes = [];
        this.sorted_candidates.forEach(item => {
            votes.push(item.votes);
        });

        return [{
            name: 'Votos',
            data: votes
        }];
    }

    get loading() {
        return this.$store.state.election.hisLoading;
    }
    get typeCode() {
        let item = Util.getTypeValueById(this.election.type_id);
        if (item == null) return '';
        return item.code;
    }
    get election() {
        return this.$store.state.election.editModel;
    }

    getWinners() {
        let winners = this.election.applicants.filter(w => w.winner).map(m => m.name).join(',');
        return winners;
    }


    mounted() {
    }
}