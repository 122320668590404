var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.list,"loading":_vm.loading,"search":_vm.search,"dense":"","custom-filter":_vm.filterTable},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('v-text-field',{staticClass:"mx-4 pt-2",attrs:{"dense":"","hide-details":"","label":"Buscar Votantes por DNI, Nombres o Tipo de Bloqueo"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","lg":"3"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"error","loading":_vm.loading},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Agregar IP")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 mx-2",attrs:{"icon":""},on:{"click":_vm.getpage}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar')))])])],1)],1)]},proxy:true},(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"item.lawyer",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.getInitials(item.lawyer.fullname)))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(item.lawyer.fullname)}}),_c('v-list-item-subtitle',{staticClass:"caption"},[_c('b',[_vm._v("DNI:")]),_vm._v(" "+_vm._s(item.lawyer.document)+" "),_c('b',{staticClass:"ml-2"},[_vm._v("Matrícula:")]),_vm._v(_vm._s(item.lawyer.code))])],1)],1)]}}],null,true)})]}},{key:"item.ban_type_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItem(item.ban_type_id).name)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","loading":_vm.loading,"text":"","icon":"","small":""},on:{"click":function($event){return _vm.deleteIP(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Eliminar ")])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"#F8BB86"}},[_vm._v("block")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("No hay ninguna IP en la Lista Negra")])]),_c('span',{staticClass:"caption mb-4"},[_vm._v(" Parece que todavía no hay ningúna IP bloqueada "),_c('br'),_vm._v(" Las IPS bloqueadas aparecerán en esta sección ")])],1)]},proxy:true}],null,true)}),_c('blacklist-form',{on:{"save-success":function($event){return _vm.getpage()}},model:{value:(_vm.showBLModal),callback:function ($$v) {_vm.showBLModal=$$v},expression:"showBLModal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }