
















































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util';
import BlacklistForm from '@/views/elections/blacklist/blacklist-form.vue';

@Component({
    components: { BlacklistForm },
})
export default class Blacklists extends AbpBase {
    menu: boolean = false;
    menus: any = {};
    reject_message: any = null;
    search: string = '';
    currentItem: any = null;
    showBLModal: boolean = false;

    columns = [
        { value: 'lawyer', text: 'Agremiado', type: 'text', sortable: false },
        { value: 'ban_type_id', text: 'Tipo de Bloqueo', type: 'text', sortable: false },
        { value: 'ip', text: 'IP', type: 'text', sortable: false },
        { value: 'created_at', text: 'Fecha de Bloqueo', type: 'text', sortable: false },      
        { value: 'action', sortable: false, width: '100px' },
    ];

    get list() {
        return this.$store.state.blacklist.list;
    }
    get loading() {
        return this.$store.state.blacklist.loading;
    }

    get election() {
        return this.$store.state.election.editModel;
    }

    getItem(state_id) {
        return Util.abp.list.getItem(state_id);
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    filterTable(value, search, item) {
        let res = search != null &&
            item.lawyer != null &&
            //item.document_number != null &&
            ((item.lawyer != null && item.lawyer.fullname.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1) ||
            (item.code != null && item.code.toString().indexOf(search) !== -1) ||
            (item.ban_type != null && item.ban_type.name.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1))

        return res;
    }

    async deleteIP(item) {
        await this.$store.dispatch({
            type: 'blacklist/delete',
            data: { id: item.id }
        });

        this.getpage();
        this.$swal('Eliminado', 'Has eliminado esta IP de la lista negra', 'success')
    }

    async create() {
        this.$store.commit('blacklist/new', { election_id: this.election.id });
        this.showBLModal = !this.showBLModal;
    }

    async getpage() {
        await this.$store.dispatch({
            type: 'blacklist/getAll',
            data: { election: this.election.id }
        });
    }

    created() {

    }

    mounted() {

    }
}