


















































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import url from '@/lib/url';
import ListBase from '@/lib/listbase';
import Election from '@/store/entities/elections/election';
import History from './history.vue';
import Applicants from './applicants.vue';
import Questions from './questions.vue';
import Results from './results.vue';
import Blacklist from './blacklist.vue';

class PageElectionRequest extends PageRequest {
    election: number = null;
}

@Component({
    components: { FormBase, VWidget, History, Applicants, Questions, Results, Blacklist },
})

export default class ElecitionForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    election: Election = new Election();
    pagerequest: PageElectionRequest = new PageElectionRequest();
    showEnroll: boolean = false;
    modalShow: boolean = true;
    urlPhoto: string = url;
    sState: any = Util.abp.list.getCodeList('E_ELECTION');
    actions: Array<any> = [
        { title: 'Anular', icon: 'close', event: this.cancel },
    ];

    filterItems = [
        this.gItem({ type: 'object', prop: 'election', label: 'Eleccion', itemText: 'description', itemSubTitle: 'notes', itemChip: 'number', itemChipColor:'state_color', size: 8, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'spacer', size: 4 }),
    ]

    /***** computed methods *****/
    get loading() {
        return this.$store.state.election.loading;
    };

    get getLoading() {
        return this.$store.state.election.getLoading;
    };

    get current_state() {
        if (this.election == undefined || this.election.state_id == undefined) return {};
        return Util.abp.list.getItem(this.election.state_id);
    }

    get votingProgress() {
        return 0;
        //return this.election.enrolled_students * 100 / this.election.capacity;
    }

    set votingProgress(value) {
        
    }

    get urlVoting() {
        return Util.abp.setting.get('URL_VOTING') + this.election.code;
    }

    get isCanceled() {
        return this.election.state_id == this.sState.AN;
    }

    get isFinished() {
        return this.election.state_id == this.sState.CE;
    }
    
    get typeCode() {
        let item = Util.getTypeValueById(this.election.type_id);
        if (item == null) return '';
        return item.code;
    }

    get typeName() {
        let item = Util.getTypeValueById(this.election.type_id);
        if (item == null) return '';
        return item.name;
    }

    /***** fields events *****/
    showButton(btn) {
        if (btn == 'start' && this.election.valid && !this.isCanceled && !this.election.in_proccess && !this.isFinished)
            return true;
        else if (btn == 'stop' && this.election.valid && !this.isCanceled && this.election.started && this.election.in_proccess && !this.isFinished)
            return true;
        else if (btn == 'close' && this.election.valid && !this.isCanceled && this.election.started && !this.isFinished)
            return true;
        else if (btn == 'password' && this.election.valid && !this.isCanceled && !this.election.started && this.getItem(this.election.security_level_id).code == 'L3' && this.election.nro_passwords == 0)
            return true;
        return false;
        
    }

    getItem(id) {
        return Util.abp.list.getItem(id);
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    changeTab(item) {
        if (item == 1) {
            //this.getApplicants();
        } else if (item == 2) {
            this.getBlackList();
        } else if (item == 3) {
            this.getHistory();
        }
    }

    getWinners() {
        if (this.isFinished) {
            let winners = this.election.applicants.filter(w => w.winner).map(m => m.name).join(',');
            return winners;
        }
        return '';
    }

    async start() {
        this.$swal({
            title: '&iquest;Inciar Votaciones?',
            html: 'Se habilitara la url para que los agremiados puedan emitir su VOTO',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Iniciar!',
            cancelButtonText: '&iexcl;Aun No!',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'election/start',
                    data: { id: this.election.id }
                });

                this.election.total_voters = model.total_voters;
                this.election.in_proccess = model.in_proccess;
                this.election.started = model.started;
                this.election.state_id = model.state_id;
            }
        });
    }

    async stop() {
        this.$swal({
            title: '&iquest;Detener Votaciones?',
            html: 'Se deshabilitara y bloqueara la emision de votos',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Detener!',
            cancelButtonText: '&iexcl;Aun No!',
            //cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'election/stop',
                    data: { id: this.election.id }
                });

                this.election.in_proccess = model.in_proccess;
                this.election.state_id = model.state_id;
            }
        });            
    }

    async close() {
        this.$swal({
            title: '&iquest;Finalizar Votaciones?',
            html: 'Al Finalizar, se consolidaran los resultados de las votaciones',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Finalizar!',
            cancelButtonText: '&iexcl;No!',
            cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'election/close',
                    data: { id: this.election.id }
                });

                this.$swal('Finalizado', 'La Votacion ha finalizado, ya puede ver los resultados', 'success');

                this.election.in_proccess = model.in_proccess;
                this.election.started = model.started;
                this.election.state_id = model.state_id;
                this.election.finish_date = model.finish_date;
                this.election.total_voters = model.total_voters;
                this.election.votes = model.votes;
                this.election.absents = model.absents;
                this.election.banned = model.banned;
                this.election.voided = model.voided;
                this.election.winner_id = model.winner_id;
                this.election.winners = model.winners;
            }
        })
    }

    async cancel() {
        this.$swal({
            title: '&iquest;Anular Votaciones?',
            html: 'Esta seguro de anular las votaciones, esta votacion ya no estara disponible, esta accion no se puede revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Anular!',
            cancelButtonText: '&iexcl;No!',
            cancelButtonColor: '#FF5252',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                let model = await this.$store.dispatch({
                    type: 'election/delete',
                    data: { id: this.election.id }
                });

                this.$swal(this.L('Votacion Anulada'), this.L('La votacion ha sido anulada'), 'success');
                                    
                this.election.in_proccess = model.in_proccess;
                this.election.started = model.started;
                this.election.state_id = model.state_id;
            }
        })
    }

    async passwords() {
        let model = await this.$store.dispatch({
            type: 'election/generatePassword',
            data: { id: this.election.id }
        });

        this.$swal(this.L('Contrasenas Generadas y Enviadas'), this.L('Se han generado las contrasenas temporales y han sido enviadas'), 'success');

        this.election.nro_passwords = model.nro_passwords;
    }
    

    /***** RETORNO DE MODAL *****/
    async getHistory() {
        await this.$store.dispatch({
            type: 'election/getHistory',
            data: { election: this.election.id }
        });
    }

    async getApplicants() {
        await this.$store.dispatch({
            type: 'election/getApplicants',
            data: { election: this.election.id }
        });
    }

    async getBlackList() {
        await this.$store.dispatch({
            type: 'blacklist/getAll',
            data: { election: this.election.id }
        });
    }


    /***** FUNCIONES DE INICIO *****/
    async loadData(id) {
        let model = await this.$store.dispatch({
            type: 'election/get',
            id: id
        });
        this.$store.commit('election/view', model);
        this.election = Util.extend(true, {}, this.$store.state.election.editModel);
    }
    
    async mounted() {
        if (this.$route.params.id != undefined) {
            this.pagerequest.election = parseInt(this.$route.params.id);
            if (this.$store.state.election.editModel.id != this.$route.params.id) {
                await this.loadData(this.$route.params.id);
            }
            this.election = Util.extend(true, {}, this.$store.state.election.editModel);
        }

        this.$store.dispatch({
            type: 'election/getAllActive'
        });
    }

    async created() {
        await this.$store.dispatch({
            type: 'position/getAllActive'
        });
    }
}
